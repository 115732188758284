<template>
  <div
      :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
      class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
            title
          }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
          class="overlay-layer rounded bg-white bg-opacity-5"
          :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!--begin::Filters-->
      <div class="d-none d-sm-flex align-items-sm-center" >
        <!--begin::Input-->
        <div>
          <el-select
              style="width: 96px;"
              class="form-select-solid"
              placeholder="Select option"
              v-model="filters.manufacturer"
              @change="updateAssetsFilterManufacturer"
          >
            <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
            <el-option label="Elster" value="Elster">Elster</el-option>
            <el-option label="GCZ" value="GCZ">GCZ</el-option>
          </el-select>
        </div>
        <!--end::Input-->
        <!--begin::Title-->
        <div class="d-flex flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal">
          <div class="flex-grow-1 me-2">

            <!--begin::Input-->
            <div>
              <el-select
                  style="width: 120px;"
                  class="form-select-solid ms-5"
                  placeholder="Select option"
                  v-model="filters.type"
                  @change="updateAssetsFilterType"
              >
                <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
                <el-option label="BK-G4T" value="BK-G4T">BK-G4T</el-option>
                <el-option label="BK-G2.5T" value="BK-G2.5T">BK-G2.5T</el-option>
              </el-select>
            </div>
            <!--end::Input-->
          </div>
        </div>
        <!--end::Title-->

        <!--begin::Title-->
        <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
          <div class="flex-grow-1 me-2">

            <!--begin::Input-->
            <div>
              <Field
                  style="width: 150px; font-size: 14px; font-weight: normal;"
                  class="form-control form-control-lg"
                  type="text"
                  name="keyword"
                  placeholder="Axtarış..."
                  autocomplete="off"
                  v-model="filters.keyword"
                  @input="updateAssetsFilterKeyword"
              />
            </div>
            <!--end::Input-->
          </div>
        </div>
        <!--end::Title-->

        <!--begin::Title-->
        <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
          <div class="flex-grow-1 me-2">

            <!--begin::Input-->
            <div>
              <el-select
                  class="form-select-solid"
                  style="width: 100px"
                  placeholder="Select option"
                  v-model="filters.claims"
                  @change="updateAssetsFilterClaims"
              >
                <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
                <el-option label="Var" value="Var">Var</el-option>
                <el-option label="Yoxdur" value="Yoxdur">Yoxdur</el-option>
              </el-select>
            </div>
            <!--end::Input-->
          </div>
        </div>
        <!--end::Title-->

        <!--begin::Chart-->
        <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
          <div class="flex-grow-1 ps-15 text-center">
            <!--begin::Input-->
            <div>
              <el-select
                  class="form-select-solid ms-7"
                  style="width: 120px"
                  placeholder="Select option"
                  v-model="filters.status"
                  @change="updateAssetsFilterStatus">
                <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
                <el-option label="İşlənmiş" value="used">İşlənmiş</el-option>
                <el-option label="Yeni" value="new">Yeni</el-option>
              </el-select>
            </div>
            <!--end::Input-->
          </div>
        </div>
        <!--end::Chart-->

        <!--begin::Section-->
        <div class="d-flex align-items-center">
          <div class="me-6 min-w-150px d-sm-flex d-none">
            <!--begin::Input-->
            <div>
              <el-select
                  class="form-select-solid"
                  placeholder="Select option"
                  v-model="filters.calibration"
                  @change="updateAssetsFilterCalibration"
              >
                <el-option label="Hamısı" value="Hamısı">Hamısı</el-option>
                <el-option label="Kalibrasiya olunub" value="Kalibrasiya olunub">Kalibrasiya olunub</el-option>
                <el-option label="Kalibrasiya olunmayıb" value="Kalibrasiya olunmayıb">Kalibrasiya olunmayıb</el-option>
                <el-option label="İstifadəyə yararsız" value="İstifadəyə yararsız">İstifadəyə yararsız</el-option>
              </el-select>
            </div>
            <!--end::Input-->
          </div>
        </div>
        <!--end::Section-->
      </div>
      <!--end::Filters-->

      <template v-for="(item, index) in items" :key="index">
        <!--begin::Item-->
        <div
              :class="{ 'mb-7': items.length - 1 !== index }"
              :key="filters"
              v-if="(filters.claims === 'Hamısı' || item.claimsValue === filters.claims) && (filters.status === 'Hamısı' || item.condition.value === filters.status) && (filters.calibration === 'Hamısı' || item.calibration.label === filters.calibration) && (filters.manufacturer === 'Hamısı' || item.manufacturer === filters.manufacturer) && (filters.type === 'Hamısı' || item.title === filters.type) && item.eq_id.match(new RegExp(filters.keyword, 'i'))"
              class="d-flex align-items-sm-center"
          >
          <!--begin::Overlay-->
          <a class="d-block overlay me-4 min-w-70px min-w-sm-100px" target="_blank" :href="`${item.photos[0].md}`">
            <!--begin::Image-->
            <div class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-100px"
                 :style="`background-image: url(${item.photos[0].sm})`">
            </div>
            <!--end::Image-->

            <!--begin::Action-->
            <div class="overlay-layer card-rounded bg-dark bg-opacity-25 shadow">
              <i class="bi bi-eye-fill text-white fs-3x"></i>
            </div>
            <!--end::Action-->
          </a>
          <!--end::Overlay-->
          <!--begin::Title-->
          <div class="d-flex flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal">
            <div class="flex-grow-1 me-2">
              <a
                  href="#"
                  class="text-gray-800 fw-bolder text-hover-primary fs-6"
              >{{ item.title }}</a
              >

              <span class="text-muted fw-bold d-block pt-1 text-gray-800">{{ item.manufacturer }}</span>

              <span class="text-gray-700 fw-bold d-block pt-1 d-sm-none">ID: {{ item.eq_id }}</span>

              <span class="text-success fw-bold d-block pt-1 d-sm-none" v-if="item.condition.value === 'new'">YENİ</span>

              <span class="text-warning fw-bold d-block pt-1 d-sm-none" v-if="item.condition.value === 'used'">İŞLƏNMİŞ</span>

              <span :class="`text-${item.calibration.class}`" class="fw-bold d-block pt-1 d-sm-none">{{ item.calibration.label }}</span>
              <span class="text-danger fw-bold d-block pt-1 d-sm-none"> <span class="svg-icon svg-icon-5 svg-icon-danger">
                  <inline-svg
                      src="media/icons/duotone/Code/Warning-1-circle.svg"  style="margin-bottom: 2px"
                  />
                </span> {{ item.claims }}</span>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Title-->
          <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
            <div class="flex-grow-1 me-2">

              <span class="text-gray-700 fw-bold d-block pt-1">ID: {{ item.eq_id }}</span>
              <span class="text-gray-700 fw-bold d-block pt-1">SN: {{ item.sn }}</span>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Title-->
          <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
            <div class="flex-grow-1 me-2">

              <!--<span class="text-gray-700 fw-bold d-block pt-1">Şikayət sayı: {{ item.claims }}</span>-->
              <span class="text-danger fw-bold d-block pt-1"> <span class="svg-icon svg-icon-1 svg-icon-danger">
                  <inline-svg
                      src="media/icons/duotone/Code/Warning-1-circle.svg" class="h-50px"
                  />
                </span> {{ item.claims }}</span>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Chart-->
          <div class="d-none flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal d-sm-flex">
            <div class="flex-grow-1">
              <apexchart
                  class="mixed-widget-4-chart"
                  :options="chartOptionsDanger"
                  :series="[100]"
                  height="150"
                  type="radialBar"
                  v-if="item.condition.value === 'used'"
              ></apexchart>
              <apexchart
                  class="mixed-widget-4-chart"
                  :options="chartOptionsSuccess"
                  :series="[100]"
                  height="150"
                  type="radialBar"
                  v-if="item.condition.value === 'new'"
              ></apexchart>
            </div>
          </div>
          <!--end::Chart-->

          <!--begin::Section-->
          <div class="d-flex align-items-center">
            <div class="me-6 min-w-150px d-sm-flex d-none">
              <span

                  :class="`badge-light-${item.calibration.class}`"
                  class="badge fs-8 fw-bolder my-2">{{ item.calibration.label }}</span>
            </div>


            <router-link :to="`/fm/${item.eq_id}`" class="btn btn-icon btn-light btn-sm border-0">
                <span class="svg-icon-2 svg-icon-primary">
                  <inline-svg
                      src="media/icons/duotone/Navigation/Arrow-right.svg"
                  />
                </span>
            </router-link>
          </div>
          <!--end::Section-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import {inject, defineComponent, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import AssetActionsDropdown from "@/components/dropdown/AssetActionsDropdown.vue";
import {Field} from "vee-validate";

export default defineComponent({
  name: "AssetsList",
  components: {Field},
  props: {
    widgetClasses: String,
    calibration: String,
    status: String,
    type: String,
    manufacturer: String,
    claims: String,
    keyword: String
  },
  setup(props) {
    /*return {
      itemsTabs,
      title,
      description
    };*/
    const colorDanger = ref("warning");

    const baseColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);
    const lightColorDanger = getCSSVariableValue("--bs-light-" + colorDanger.value);
    const labelColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);

    const chartOptionsDanger = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorDanger,
              fontSize: "14px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return "İŞLƏNMİŞ";
              }
            }
          },
          track: {
            background: lightColorDanger,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorDanger],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };


    const colorSuccess = ref("success");

    const baseColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);
    const lightColorSuccess = getCSSVariableValue("--bs-light-" + colorSuccess.value);
    const labelColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);

    const chartOptionsSuccess = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorSuccess,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return "YENİ";
              }
            }
          },
          track: {
            background: lightColorSuccess,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorSuccess],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };


    return {
      chartOptionsDanger,
      chartOptionsSuccess
    };
  },
  data: function () {
    const filters = {calibration: "Hamısı", status: "Hamısı", manufacturer: "Hamısı", type: "Hamısı", claims: "Hamısı", keyword: ""};
    const items = [];
    const title = "Sayğac reestri";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function () {
    emitter.on("filterAssetsList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.calibration = filterDataFromEvent.calibration;
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      this.filters.manufacturer = filterDataFromEvent.manufacturer;
      this.filters.claims = filterDataFromEvent.claims;
      this.filters.keyword = filterDataFromEvent.keyword;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.filters.status = "Hamısı";
      this.filters.calibration = "Hamısı";
      this.filters.type = "Hamısı";
      this.filters.manufacturer = "Hamısı";
      this.filters.claims = "Hamısı";
      //this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.filters.status = selected;
    },
    updateAssetsFilterCalibration: function (selected) {
      this.filters.calibration = selected;
    },
    updateAssetsFilterManufacturer: function (selected) {
      this.filters.manufacturer = selected;
    },
    updateAssetsFilterType: function (selected) {
      this.filters.type = selected;
    },
    updateAssetsFilterKeyword: function (selected) {
      this.filters.keyword = selected.target.value;
    },
    updateAssetsFilterClaims: function (selected) {
      this.filters.claims = selected;
    },
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/polarus/gcz/fm/list")
          .then(data => {
            //console.log(data);
            //update project tabs table test
            this.title = "Sayğaclar";
            this.description = "Sayğac haqqında ətraflı məlumatları əldə etmək üçün sətrin sağ hissəsində yerləşən ox işarəsinə klik edin";
            this.$nextTick(() => {
              this.items = data.data.data;
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            console.log(response);
          });
    }
  }
});
</script>
