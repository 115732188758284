
import { defineComponent, onMounted, onBeforeMount } from "vue";
import AssetsList from "@/components/widgets/tables/AssetsList.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";

export default defineComponent({
  name: "assetsListPage",
  components: {
    AssetsList
  },
  methods: {
    filterAssetsListTable(options) {
      this.status = options.status;
      this.customer = options.customer;
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Sayğac reestri");
      setCurrentPageBreadcrumbs("Sayğac reestri", ["Sayğaclar"]);
      setCurrentPageToolbarType("assetsList");
    });
  },
  data() {
    const customer = "All";
    const status = "All";
    return {
      customer,
      status
    }
  }
});
