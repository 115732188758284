
import {inject, defineComponent, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import AssetActionsDropdown from "@/components/dropdown/AssetActionsDropdown.vue";
import {Field} from "vee-validate";

export default defineComponent({
  name: "AssetsList",
  components: {Field},
  props: {
    widgetClasses: String,
    calibration: String,
    status: String,
    type: String,
    manufacturer: String,
    claims: String,
    keyword: String
  },
  setup(props) {
    /*return {
      itemsTabs,
      title,
      description
    };*/
    const colorDanger = ref("warning");

    const baseColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);
    const lightColorDanger = getCSSVariableValue("--bs-light-" + colorDanger.value);
    const labelColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);

    const chartOptionsDanger = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorDanger,
              fontSize: "14px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return "İŞLƏNMİŞ";
              }
            }
          },
          track: {
            background: lightColorDanger,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorDanger],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };


    const colorSuccess = ref("success");

    const baseColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);
    const lightColorSuccess = getCSSVariableValue("--bs-light-" + colorSuccess.value);
    const labelColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);

    const chartOptionsSuccess = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorSuccess,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return "YENİ";
              }
            }
          },
          track: {
            background: lightColorSuccess,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorSuccess],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };


    return {
      chartOptionsDanger,
      chartOptionsSuccess
    };
  },
  data: function () {
    const filters = {calibration: "Hamısı", status: "Hamısı", manufacturer: "Hamısı", type: "Hamısı", claims: "Hamısı", keyword: ""};
    const items = [];
    const title = "Sayğac reestri";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function () {
    emitter.on("filterAssetsList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.calibration = filterDataFromEvent.calibration;
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      this.filters.manufacturer = filterDataFromEvent.manufacturer;
      this.filters.claims = filterDataFromEvent.claims;
      this.filters.keyword = filterDataFromEvent.keyword;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    resetFilters: function() {
      console.log("reset");
      this.filters.status = "Hamısı";
      this.filters.calibration = "Hamısı";
      this.filters.type = "Hamısı";
      this.filters.manufacturer = "Hamısı";
      this.filters.claims = "Hamısı";
      //this.filterAssetsList();
    },
    updateAssetsFilterStatus: function (selected) {
      this.filters.status = selected;
    },
    updateAssetsFilterCalibration: function (selected) {
      this.filters.calibration = selected;
    },
    updateAssetsFilterManufacturer: function (selected) {
      this.filters.manufacturer = selected;
    },
    updateAssetsFilterType: function (selected) {
      this.filters.type = selected;
    },
    updateAssetsFilterKeyword: function (selected) {
      this.filters.keyword = selected.target.value;
    },
    updateAssetsFilterClaims: function (selected) {
      this.filters.claims = selected;
    },
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/polarus/gcz/fm/list")
          .then(data => {
            //console.log(data);
            //update project tabs table test
            this.title = "Sayğaclar";
            this.description = "Sayğac haqqında ətraflı məlumatları əldə etmək üçün sətrin sağ hissəsində yerləşən ox işarəsinə klik edin";
            this.$nextTick(() => {
              this.items = data.data.data;
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            console.log(response);
          });
    }
  }
});
